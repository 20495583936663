 

/*---------------------------------------------------------------------------
  IMPORT 
  --------------------------------------------------------------------------- */

  // import { gsap, Linear } from 'gsap/dist/gsap.min.js';
  // import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min.js';
  // import { ScrollSmoother } from 'gsap/dist/ScrollSmoother.min.js';
  import { SplitText } from 'gsap/dist/SplitText.min.js';
  // import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin.min.js';
  // import { CustomEase } from 'gsap/dist/CustomEase.min.js';
  // import { CustomEase } from 'gsap/dist/CustomEase.min.js';
  // import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';


  import $ from 'jquery';


 /*---------------------------------------------------------------------------
  SCROLL SMOOTHER DOCUMENT                                                                                                                            
  --------------------------------------------------------------------------- */


  let smoother = ScrollSmoother.create({
    smooth: 1.5,   
    effects: true,  
    normalizeScroll: true,
    ignoreMobileResize: true,
    onStop: () => {}
  });

 /*---------------------------------------------------------------------------
  ANCHOR LINKS                                                                                                                          
  --------------------------------------------------------------------------- */

  gsap.utils.toArray("a[href^='#']").forEach(function (button, i) {
    button.addEventListener("click", (e) => {
       e.preventDefault();
      var id = e.target.getAttribute("href");
      console.log(id);
      smoother.scrollTo(id, true, "top top");
      e.preventDefault();
    });
  });

  // to view navigate to -  https://cdpn.io/pen/debug/XWVvMGr#section3
  window.onload = (event) => {
    console.log("page is fully loaded");

    let urlHash = window.location.href.split("#")[1];
    let scrollElem = document.querySelector("#" + urlHash);
    console.log(scrollElem, urlHash);

    if (urlHash && scrollElem) {
      gsap.to(smoother, {
        scrollTop: smoother.offset(scrollElem, "top top"),
        duration: 1,
        delay: 0.5
      });
    }
  };


 /*---------------------------------------------------------------------------
  PARALLAX PANEL HERO IMAGE                                                                                                                           
  --------------------------------------------------------------------------- */

  // background image
  let heropanelsParallaxImage = document.querySelectorAll('.js-parallax-bgimage');
  if(heropanelsParallaxImage) {
    heropanelsParallaxImage.forEach((heropanel)=> {
      const heroImage = heropanel.querySelector('.c-heropanel__media img')
      smoother.effects(heroImage, {speed: 0.75, lag: 0});
    })
  }

  // background video
  let heropanelsParallaxVideo = document.querySelectorAll('.js-parallax-bgvideo');
  if(heropanelsParallaxVideo) {
    heropanelsParallaxVideo.forEach((heropanel)=> {
      const heroVideo = heropanel.querySelector('.c-heropanel__media video')
      smoother.effects(heroVideo, {speed: 0.75, lag: 0});
    })
  }

  // text overlay
  let heropanelsParallaxText = document.querySelectorAll('.js-parallax-textoverlay');
  if(heropanelsParallaxText) {
    heropanelsParallaxText.forEach((heropanel)=> {
      const heroText = heropanel.querySelector('.c-heropanel__overlay')
      smoother.effects(heroText, {speed: 0.6, lag: 0});
    })
  }
  


/*---------------------------------------------------------------------------
  BRAND TILES                                                                                                                          
  --------------------------------------------------------------------------- */

  const brandTiles = document.querySelectorAll('.tile--brands')

  if(brandTiles) {

    brandTiles.forEach(brandTile => {

      let brandTileFigure = brandTile.querySelector('figure')

      let tl_brandTiles = gsap.timeline({
          scrollTrigger: {
            trigger: brandTile,
            start: 'top bottom-=300', 
            end: 'top top',
            scrub: true,
            onLeave: () => {},
            onLeaveBack: () => {},        
            onEnter: () => {},     
            onEnterBack: () => {}
          },
        }).from(brandTileFigure, {
          duration: 2,
          opacity: 0,
          scale: 0.5,
          ease: 'power4.out', 
        });

    }) 
  }


/*---------------------------------------------------------------------------
  TILE FLIPPING
  --------------------------------------------------------------------------- */

  const tileFlip = document.querySelectorAll('.tile-flip')

  if(tileFlip) {

    tileFlip.forEach(tile => {

      const tileFlipBtnFlip = tile.querySelector('button.flip')

      tileFlipBtnFlip.addEventListener('click', event => {
        event.preventDefault()

        tileFlip.forEach(tilesAll => {
          tilesAll.classList.remove('js-active')
        })
        tile.classList.add('js-active')
      })

    })


    tileFlip.forEach(tile => {
      const tileFlipBtnClose = tile.querySelector('button.close')
      tileFlipBtnClose.addEventListener('click', () => {
        event.preventDefault()
        tile.classList.remove('js-active')
      })    
    })

  }


 /*---------------------------------------------------------------------------
  CALLOUT PANEL TEXT ANIMATION                                                                                                                      
  --------------------------------------------------------------------------- */
  
  const calloutHeadline = document.querySelector('.js-parallax-textanimation .c-heropanel__callout p')    

  let st_calloutHeadline = new SplitText(calloutHeadline, {type:'words, chars'})
  // let tl_callout = gsap.timeline();

  let tl_callout = gsap.timeline({
    scrollTrigger: {
      trigger: calloutHeadline,
      start: 'top bottom',
      end: 'top top',
      scrub: true,
      onLeave: () => {},
      onLeaveBack: () => {},        
      onEnter: () => {},     
      onEnterBack: () => {}
    },
  }).from(st_calloutHeadline.chars, {
    duration: 2, 
    opacity: 0, 
    rotateY: 90, 
    ease: 'power4.out',  
    stagger: 0.02
  }, '2') 




 /*---------------------------------------------------------------------------
  PIN RANCHERA GRILL                                                                                                                           
  --------------------------------------------------------------------------- */

  let rancheraGrillPin = document.getElementById('pin-ranchera')

  if(rancheraGrillPin) {

    console.log('ranchera')

    let tl_statsPinIntro = gsap.timeline({
      scrollTrigger: {
        trigger: '#pin-ranchera',
        start: 'top top+=150',
        endTrigger: '#row-grill',
        end: 'bottom bottom-=150',
        pin: true,
        onLeave: () => {

        },
        onLeaveBack: () => {
         
        },        
        onEnter: () => {
         
        },     
        onEnterBack: () => {
              
        }
      },
    });
  }


/*---------------------------------------------------------------------------
  SPRITUOSEN PANELS
  --------------------------------------------------------------------------- */






/*---------------------------------------------------------------------------
  ANIMATED MAP                                                                                                                              
  --------------------------------------------------------------------------- */

  const animatedMap = document.getElementById('animated-map')


  if(animatedMap) { 

    const findAnimatedMapEls = gsap.utils.selector(animatedMap);

    let mapAnim      = new TimelineMax({paused:true}),   
        mapSVG       = animatedMap,    
        mapMap       = findAnimatedMapEls('#map-continents'),
        mapItems     = findAnimatedMapEls('.map-item'),
        mapMarker    = findAnimatedMapEls('#map-marker'),
        mapRoute     = findAnimatedMapEls('#map-route'),
        mapPath      = findAnimatedMapEls('#map-path'),
        mapCow       = findAnimatedMapEls('#map-cows'),
        mapShip      = findAnimatedMapEls('#map-ship'),
        mapHidden    = findAnimatedMapEls('.map-start-hidden');   
          
    // unhide hidden elements to make 'from' work in tween
    // elements initially hidden by inlined CSS to avoid initial visibility
    // mapHidden.forEach(hiddenEls => {
    //   // hiddenEls.style.opacity = 1
    // })

    // map animation sequence
    mapAnim
      .staggerFrom(mapItems, 1.5, {opacity: 0, scale: 0, transformOrigin: '50% 50%', ease:Back.easeOut.config(2)}, 0.2, '-=0')
      .from(mapCow, 0.5, {opacity: 0, scale: 0, transformOrigin: '50% 50%', ease:Back.easeOut.config(2)}, '2')
      .from(mapMarker, 0.5, {opacity: 0, ease:Back.easeOut.config(2)}, '3')
      .from(mapRoute, .5, {drawSVG:'50% 50%', ease:Linear.easeInOut}, '1.5')
      .from(mapShip, 0.5, {opacity: 0, scale: 0, transformOrigin: '50% 50%', ease:Back.easeOut.config(2)}, '1')
    ;

    // start animation
    mapAnim.play(); 

    // ship movement
    let action = gsap.timeline({defaults: {duration: 3},
      scrollTrigger: {
        trigger: '#animated-map',
        // endTrigger: '#article-664',
        pin: '#animated-map',
        pinSpacing: true,        
        scrub: true,
        start: 'top top+=60',
        end: 'bottom top',  
      }})
      .from('#map-ship', 2, {motionPath: {
        path: '#map-path', 
        align: '#map-path', 
        alignOrigin: [0.5, 0.5]
      }
    }, 0); 

    // motion path helper
    // gsap.to('#map-ship', 2, {
    //   duration: 3,
    //   motionPath: {
    //     path: '#map-path', 
    //     align: '#map-path', 
    //     alignOrigin: [0.5, 0.5]
    //   }   
    // }, 6);      
    // MotionPathHelper.create('#map-ship'); 

  } 




/*---------------------------------------------------------------------------
  INTERACTIVE COW                                                                                                                               
  --------------------------------------------------------------------------- */

  var mooArea      = $('#moo-area');

if(mooArea.length) {

  var mooAnim      = new TimelineMax({paused:true}),   
      argentina    = $('#continent'),
      // cow items
      mooCow       = mooArea.find('#cow-outline'),
      mooCowFill   = mooArea.find('#cow-fill'),
      mooCutPaths  = mooArea.find('#cow-cutpaths'),
      mooCutPath   = mooArea.find('.cut-path'),
      mooCutGroup  = mooArea.find('.cut-group'),
      mooCuts      = mooArea.find('.cut'),
      mooInfoGroup = mooArea.find('.cut-infogroup'),
      // infobox
      mooBoxArea   = $('#box-area'),
      mooBox       = mooBoxArea.find('.moo-box'),
      mooBoxItem   = mooBoxArea.find('.cutitem')
      ;


  // play audio on hovering cow head

  // var audioElement = document.createElement('audio');
  // audioElement.setAttribute('src', '/files/content/audio/moo.mp3');

  // $('#moo-audio').mouseover(function() {
  //   audioElement.play();
  // });

  // start animation of cow

  mooAnim
    .set(mooCutGroup, {transformOrigin:"50% 50%", scale:0.5})
    // .to(argentina, 1, {morphSVG:mooCow, ease:Power1.easeInOut})
    // .to(argentina, 2, {opacity:1, ease:Linear.easeOut}, "-=0.3") 
    .set(mooCow, {"visibility":"visible"}, "1")
    .from(mooCow, 2, {drawSVG:"50% 50%", ease:Linear.easeInOut}, "1")
    .to(mooCow, 0.5, {opacity:0, ease:Linear.easeOut}, "-=0") 
    .to(mooCowFill, 0.5, {opacity:1, ease:Linear.easeOut}, "-=0") 
    .to(mooCutPaths, .5, {opacity:1, ease:Linear.easeOut}, "-=.7") 
    .set(mooCutGroup, {'display':'block'}, "-=0") 
    .staggerTo(mooCutGroup, 1, {opacity:1, scale:1, ease:Back.easeOut.config(2)}, 0.1, "-=0")
    .to($('#moo .bottomline'), 1, {opacity:1, ease:Linear.easeInOut},"-=.5")
    .set(mooInfoGroup, {'display':'block', onComplete: function(){

      $('#roastbeef .cut').trigger('mouseover');
      $('#roastbeef .cut').trigger('click');

      setTimeout(function(){
        $('#roastbeef .cut').trigger('mouseleave');
      }, 2000); 

    }}, "-=.5")   
  ;  


  // Firefox Fix: https://greensock.com/forums/topic/18856-draw-svg-issue-with-firefox/
  MorphSVGPlugin.convertToPath("line");

  // animating cuts on mouseover

  mooCutGroup.each(function() {

    var mooCutAnim = new TimelineMax({paused:true}),  
        cutName    = $(this).attr('id');

    mooCutAnim
      .from($(this).find('.infocircle'), .3, {drawSVG:"50% 50%", ease:Linear.easeInOut})
      .from($(this).find('.infocircle'), .3, {"fill-opacity":0, ease:Linear.easeInOut}, "-=.1")
      .from($(this).find('.infoline'), .3, {drawSVG:"0%", ease:Linear.easeOut}, "-=.1")
      .from($(this).find('.infotitle'), .3, {"fill-opacity":0, ease:Linear.easeInOut}, "-=.1") 

    // start/reverse infotitle animations on mouseover

    $(this).find('.cut').mouseover(function() {
      mooCutAnim.play();
    }).mouseout(function() {
      mooCutAnim.reverse();
    });

    // handle appearance of info box on cut clicks

    $(this).find('.cut').click(function() {

      var cutIDName = $(this).parents('.cut-group').attr('id');

      // set cut-specific id for infobox
      mooBox.attr('id', 'box-'+cutIDName);

      // close all formerly opened infobox accordeons
      mooBoxItem.removeClass('open');

      // close old infobox 
      if($('.moo-box').hasClass('active')){
        $('.moo-box').removeClass('active');
        $('.moo-box p.apurocampo').removeClass('fadein');
      }      

      // open new infobox
      setTimeout(function(){

        // build infobox based on content from JSON file
        // if(!$('#box-'+cutIDName).length) {
          grabJSON(infobox, 'blackranch', cutIDName); 
        // }

        $('#box-'+cutIDName).addClass('active');
        setTimeout(function(){
          $('.moo-box p.apurocampo').addClass('fadein');
        }, 500);            


        $('.moo-box .box-inner .cutitem').removeClass('open'); 
        

      }, 200);      
       
      // open first accordeon of new infobox 
      setTimeout(function(){
        $('.moo-box .box-inner .cutitem.first').addClass('open'); 
      }, 500);

    });

  });  

  // infobox accordeon

  RefreshClickListener();  

  function RefreshClickListener() {
    $('.moo-box .cutitem').off().on('click', function(){
      $('.cutitem').not($(this)).removeClass('open');
      $(this).toggleClass('open'); 
    });
  }


  // testing
  function infobox(content) {
    console.log(content); 
  }


  function grabJSON(infobox, brand, cut) {

    $.getJSON( "/files/content/data/moo.json", function(data) {
    
      var subcuts = data[brand][cut].subcuts,
          cutHeadline = data[brand][cut]['name'],
          subcutHTML = [],
          i = 1; 

      // set headlines depending on amount of subcuts

      var boxHeadline  = Object.keys(subcuts).length > 1 ? '<h2>' + cutHeadline + '</h2>' : '',
          itemHeadline = Object.keys(subcuts).length > 1 ? 'h3' : 'h2';

      // set apurocamp logo and link depending on availability

      var availabiltyBR  = data[brand][cut]['marke'] === "blackranch" ?  "auch" : "nur",
          // availabiltyAPC = data[brand][cut]['marke'] === "apurocampo" ? '<div class="apurocampo">Dieses Teilstück ist ' + availabiltyBR + ' unter der Marke A Puro Campo verfügbar. <a href="row-apc">Mehr Infos</a></div>' : '';
          availabilities = Object.keys(subcuts).length > 1 ? 'Diese Teilstücke sind ' : 'Dieses Teilstück ist ',
          availabiltyAPC = '<p class="apurocampo">' + availabilities + availabiltyBR + ' unter unserer Marke <a href="row-apc">A Puro Campo</a> verfügbar.</p>';

          // console.log(data[brand][cut]['marke'] + ':' + availabiltyAPC);

      // create html for infobox

      $.each(data[brand][cut]['subcuts'], function(k, cutdata) {

        // set variables for positioning item text depending on item style
        if(data[brand][cut]['style'] === "style1") {
          var itemText = i > 1 ?  "" : '<p>' + cutdata.text + '</p>',
              subitemText = '';
        } else {
          var itemText = '',
              subitemText = '<p>' + cutdata.text + '</p>';
        }

        // set variables for css classes depending on count and item style    
        var firstClass = i > 1 ?  "" : " first";
        var smallClass = data[brand][cut]['style'] === "style1" ?  " small" : "";

        // create html for subcut items
        subcutHTML.push(itemText + '<div class="cutitem' + firstClass + '"><' + itemHeadline + '>' + cutdata.name + '</' + itemHeadline + '><div class="infobox' + smallClass + '">' + subitemText + '<ul><li>' + cutdata.list1 + '</li><li>' + cutdata.list2 + '</li><li>' + cutdata.list3 + '</li></ul></div></div>');
        
        i++;

      });

      // remove previous and append new html content to moo infobox

      $('#box-area').empty().append(
        '<div class="moo-box" id="box-' + cut + '">' +
        '<div class="box-inner">'+ boxHeadline + subcutHTML.join('') + '</div>' + 
        '<div class="box-legend"><img class="moo-logo" src="/files/layout/design/logo_apurocampo-white-nobg.png"><img class="moo-logo" src="/files/layout/design/logo_blackranch-traditional-white.png"><img class="moo-logo" src="/files/layout/design/logo_blackranch-marbling-white.png"></div>' + 
        '</div>'
      );

      setTimeout(function(){
        $('.moo-box').addClass('active');
        RefreshClickListener();  
      }, 100);   


    }); 

  } 

  // testing
  // grabJSON(infobox, 'blackranch', 'roastbeef');  


  // $('#row-moo').waypoint(function(direction) { 
     mooAnim.play();                                                    
  // },{offset: 700});   

  // control buttons for testing

  $('#btn-play').click(function(){
    mooAnim.play();
  });
  $('#btn-reverse').click(function(){
    mooAnim.reverse();
  });
  $('#btn-restart').click(function(){
    mooAnim.restart();
  });
  $('#btn-slomo').click(function(){
    mooAnim.timeScale(0.4).restart();
  });

 }



/*---------------------------------------------------------------------------
  INTERACTIVE COW                                                                                                                               
  --------------------------------------------------------------------------- */

 //  const argentina = document.getElementById('continent');
 //  const mooArea = document.getElementById('moo-area');
 //  const mooBoxArea = document.getElementById('box-area');

 //  if(mooArea) {

 //    const findMooElements = gsap.utils.selector(mooArea);
 //    const findBoxElements = gsap.utils.selector(mooBoxArea);

 //    const mooAnim      = new TimelineMax({paused:true}),   
 //          mooCow       = findMooElements('#cow-outline'),
 //          mooCowFill   = findMooElements('#cow-fill'),
 //          mooCutPaths  = findMooElements('#cow-cutpaths'),
 //          mooCutPath   = findMooElements('.cut-path'),
 //          mooCutGroups = findMooElements('.cut-group'),
 //          mooCuts      = findMooElements('.cut'),
 //          mooInfoGroup = findMooElements('.cut-infogroup'),
 //          mooBox       = findBoxElements('.moo-box'),
 //          mooBoxItem   = findBoxElements('.cutitem')
 //        ;


 //  // play audio on hovering cow head

 //  // var audioElement = document.createElement('audio');
 //  // audioElement.setAttribute('src', '/files/content/audio/moo.mp3');

 //  // $('#moo-audio').mouseover(function() {
 //  //   audioElement.play();
 //  // });

 //  // start animation of cow

 //  mooAnim
 //    .set(mooCutGroups, {transformOrigin:"50% 50%", scale:0.5})
 //    .set(mooCow, {"visibility":"visible"}, "1")
 //    .from(mooCow, 2, {drawSVG:"50% 50%", ease:Linear.easeInOut}, "1")
 //    .to(mooCow, 0.5, {opacity:0, ease:Linear.easeOut}, "-=0") 
 //    .to(mooCowFill, 0.5, {opacity:1, ease:Linear.easeOut}, "-=0") 
 //    .to(mooCutPaths, .5, {opacity:1, ease:Linear.easeOut}, "-=.7") 
 //    .set(mooCutGroups, {'display':'block'}, "-=0") 
 //    .staggerTo(mooCutGroups, 1, {opacity:1, scale:1, ease:Back.easeOut.config(2)}, 0.1, "-=0")
 //    .to($('#moo .bottomline'), 1, {opacity:1, ease:Linear.easeInOut},"-=.5")
 //    .set(mooInfoGroup, {'display':'block', onComplete: function(){

 //      $('#roastbeef .cut').trigger('mouseover');
 //      $('#roastbeef .cut').trigger('click');

 //      setTimeout(function(){
 //        $('#roastbeef .cut').trigger('mouseleave');
 //      }, 2000); 

 //    }}, "-=.5")   
 //  ;  


 //  // Firefox Fix: https://greensock.com/forums/topic/18856-draw-svg-issue-with-firefox/
 //  MorphSVGPlugin.convertToPath("line");

 //  // animating cuts on mouseover

 //  mooCutGroups.forEach(mooCutGroup => {

 //    let mooCutAnim = new TimelineMax({paused:true}),  
 //        cutName    = mooCutGroup.getAttribute('id');

 //    mooCutAnim
 //      .from(mooCutGroup.querySelector('.infocircle'), .3, {drawSVG:"50% 50%", ease:Linear.easeInOut})
 //      .from(mooCutGroup.querySelector('.infocircle'), .3, {"fill-opacity":0, ease:Linear.easeInOut}, "-=.1")
 //      .from(mooCutGroup.querySelector('.infoline'), .3, {drawSVG:"0%", ease:Linear.easeOut}, "-=.1")
 //      .from(mooCutGroup.querySelector('.infotitle'), .3, {"fill-opacity":0, ease:Linear.easeInOut}, "-=.1") 


 //    // start/reverse infotitle animations on mouseover
 //    mooCutGroup.querySelector('.cut').addEventListener('mouseover', event => {
 //      mooCutAnim.play();
 //    })
 //    mooCutGroup.querySelector('.cut').addEventListener('mouseout', event => {
 //      mooCutAnim.reverse();
 //    })

 //    // handle appearance of info box on cut clicks
 //    mooCutGroup.querySelector('.cut').addEventListener('click', event => {

 //      let cutIDName = $(this).parents('.cut-group').attr('id');

 //      // set cut-specific id for infobox
 //      mooBox.setAttribute('id', 'box-'+cutIDName);

 //      // close all formerly opened infobox accordeons
 //      mooBoxItem.removeClass('open');

 //      // close old infobox 
 //      if(mooBox.hasClass('active')){
 //        mooBox.classList.remove('active');
 //        mooBox.querySelector('p.apurocampo').classList.remove('fadein');
 //      }      

 //      // open new infobox
 //      setTimeout(function(){

 //        // build infobox based on content from JSON file
 //        // if(!$('#box-'+cutIDName).length) {
 //          grabJSON(infobox, 'blackranch', cutIDName); 
 //        // }

 //        $('#box-'+cutIDName).addClass('active');
 //        setTimeout(function(){
 //          $('.moo-box p.apurocampo').addClass('fadein');
 //        }, 500);            


 //        $('.moo-box .box-inner .cutitem').removeClass('open'); 
        

 //      }, 200);      
       
 //      // open first accordeon of new infobox 
 //      setTimeout(function(){
 //        $('.moo-box .box-inner .cutitem.first').addClass('open'); 
 //      }, 500);

 //    })

 //  })



 //  // infobox accordeon

 //  RefreshClickListener();  

 //  function RefreshClickListener() {
 //    $('.moo-box .cutitem').off().on('click', function(){
 //      $('.cutitem').not($(this)).removeClass('open');
 //      $(this).toggleClass('open'); 
 //    });
 //  }


 //  // testing
 //  function infobox(content) {
 //    console.log(content); 
 //  }


 //  function grabJSON(infobox, brand, cut) {

 //    $.getJSON( "/files/content/data/moo.json", function(data) {
    
 //      let subcuts = data[brand][cut].subcuts,
 //          cutHeadline = data[brand][cut]['name'],
 //          subcutHTML = [],
 //          i = 1; 

 //      // set headlines depending on amount of subcuts
 //      let boxHeadline  = Object.keys(subcuts).length > 1 ? '<h2>' + cutHeadline + '</h2>' : '',
 //          itemHeadline = Object.keys(subcuts).length > 1 ? 'h3' : 'h2';

 //      // set apurocamp logo and link depending on availability
 //      let availabiltyBR  = data[brand][cut]['marke'] === "blackranch" ?  "auch" : "nur",
 //          // availabiltyAPC = data[brand][cut]['marke'] === "apurocampo" ? '<div class="apurocampo">Dieses Teilstück ist ' + availabiltyBR + ' unter der Marke A Puro Campo verfügbar. <a href="row-apc">Mehr Infos</a></div>' : '';
 //          availabilities = Object.keys(subcuts).length > 1 ? 'Diese Teilstücke sind ' : 'Dieses Teilstück ist ';
 //          availabiltyAPC = '<p class="apurocampo">' + availabilities + availabiltyBR + ' unter unserer Marke <a href="row-apc">A Puro Campo</a> verfügbar.</p>';

 //          // console.log(data[brand][cut]['marke'] + ':' + availabiltyAPC);

 //      // create html for infobox

 //      $.each(data[brand][cut]['subcuts'], function(k, cutdata) {

 //        // set variables for positioning item text depending on item style
 //        if(data[brand][cut]['style'] === "style1") {
 //          var itemText = i > 1 ?  "" : '<p>' + cutdata.text + '</p>',
 //              subitemText = '';
 //        } else {
 //          var itemText = '',
 //              subitemText = '<p>' + cutdata.text + '</p>';
 //        }

 //        // set variables for css classes depending on count and item style    
 //        var firstClass = i > 1 ?  "" : " first";
 //        var smallClass = data[brand][cut]['style'] === "style1" ?  " small" : "";

 //        // create html for subcut items
 //        subcutHTML.push(itemText + '<div class="cutitem' + firstClass + '"><' + itemHeadline + '>' + cutdata.name + '</' + itemHeadline + '><div class="infobox' + smallClass + '">' + subitemText + '<ul><li>' + cutdata.list1 + '</li><li>' + cutdata.list2 + '</li><li>' + cutdata.list3 + '</li></ul></div></div>');
        
 //        i++;

 //      });

 //      // remove previous and append new html content to moo infobox

 //      $('#box-area').empty().append(
 //        '<div class="moo-box" id="box-' + cut + '">' +
 //        '<div class="box-inner">'+ boxHeadline + subcutHTML.join('') + '</div>' + 
 //        '<div class="box-legend"><img class="moo-logo" src="/files/layout/design/logo_apurocampo-white-nobg.png"><img class="moo-logo" src="/files/layout/design/logo_blackranch-traditional-white.png"><img class="moo-logo" src="/files/layout/design/logo_blackranch-marbling-white.png"></div>' + 
 //        '</div>'
 //      );

 //      setTimeout(function(){
 //        $('.moo-box').addClass('active');
 //        RefreshClickListener();  
 //      }, 100);   


 //    }); 

 //  } 

 //  // testing
 //  // grabJSON(infobox, 'blackranch', 'roastbeef');  


 //  // $('#row-moo').waypoint(function(direction) { 
 //     mooAnim.play();                                                    
 //  // },{offset: 700});   

 //  // // control buttons for testing

 //  // $('#btn-play').click(function(){
 //  //   mooAnim.play();
 //  // });
 //  // $('#btn-reverse').click(function(){
 //  //   mooAnim.reverse();
 //  // });
 //  // $('#btn-restart').click(function(){
 //  //   mooAnim.restart();
 //  // });
 //  // $('#btn-slomo').click(function(){
 //  //   mooAnim.timeScale(0.4).restart();
 //  // });

 // }

