
/*---------------------------------------------------------------------------
  ___ __  __ ____   ___  ____ _____ ____  
 |_ _|  \/  |  _ \ / _ \|  _ \_   _/ ___| 
  | || |\/| | |_) | | | | |_) || | \___ \ 
  | || |  | |  __/| |_| |  _ < | |  ___) |
 |___|_|  |_|_|    \___/|_| \_\|_| |____/ 

  --------------------------------------------------------------------------- */
 
/*---------------------------------------------------------------------------
  IMPORT GSAP
  ---------------------------------------------------------------------------

  https://greensock.com/docs/v3/Installation
  https://greensock.com/profile/36429-florianp/content/?do=dashboard
  https://greensock.com/docs/v2/NPMUsage

  Contents of .npmrc file
  //npm.greensock.com/:_authToken=c04575dd-9921-4eef-961e-06e05e663754
  @gsap:registry=https://npm.greensock.com

  First time install
  npm install gsap@npm:@gsap/shockingly

  --------------------------------------------------------------------------- */

  import { gsap, Linear } from "gsap/dist/gsap.min.js";
  import { ScrollTrigger } from "gsap/dist/ScrollTrigger.min.js";
  import { ScrollSmoother } from "gsap/dist/ScrollSmoother.min.js";
  import { SplitText } from "gsap/dist/SplitText.min.js";
  import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin.min.js";
  import { CustomEase } from "gsap/dist/CustomEase.min.js";
  import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin.js"; 
  import { MorphSVGPlugin } from "gsap/dist/MorphSVGPlugin";

  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, DrawSVGPlugin, CustomEase, MotionPathPlugin, MorphSVGPlugin);

  window.gsap = gsap
  window.ScrollTrigger = ScrollTrigger
  window.ScrollSmoother = ScrollSmoother
  window.SplitText = SplitText
  window.DrawSVGPlugin = DrawSVGPlugin
  window.CustomEase = CustomEase
  window.MotionPathPlugin = MotionPathPlugin
  window.MorphSVGPlugin = MorphSVGPlugin


/*---------------------------------------------------------------------------
  IMPORT JQUERY
  --------------------------------------------------------------------------- */

  import $ from "jquery";
  // window.jquery = jquery
  window.$ = $


/*---------------------------------------------------------------------------
  IMPORT LAZYSIZES
  https://github.com/aFarkas/lazysizes#readme
  --------------------------------------------------------------------------- */

  import 'lazysizes/lazysizes.js'; 
  // import 'lazysizes/plugins/bgset/ls.bgset';       
  // import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';       
  // import 'lazysizes/plugins/object-fit/ls.object-fit';     



/*---------------------------------------------------------------------------
  ____    _    _   _ ____  ____   _____  __
 / ___|  / \  | \ | |  _ \| __ ) / _ \ \/ /
 \___ \ / _ \ |  \| | | | |  _ \| | | \  / 
  ___) / ___ \| |\  | |_| | |_) | |_| /  \ 
 |____/_/   \_\_| \_|____/|____/ \___/_/\_\
                                                                           
  --------------------------------------------------------------------------- */


  import {} from "./modules/accordion.js"


/*---------------------------------------------------------------------------
  UTILITIES                                                                                                                    
  --------------------------------------------------------------------------- */

  import {scrollbarWidth} from './modules/utilities.js'; 
  scrollbarWidth(); 

  import {viewportHeight} from './modules/utilities.js'; 
  viewportHeight();  

  // import {maxHeight} from './modules/utilities.js'; 
  // maxHeight(); 



/*---------------------------------------------------------------------------
  GRID GAP HELPER
  For nested grid layouts                                                                                                                                 
  --------------------------------------------------------------------------- */

  import {gridGapHelper} from './modules/utilities.js';
  gridGapHelper();  


/*---------------------------------------------------------------------------
  NAVIGATION                                                                                                                              
  --------------------------------------------------------------------------- */              

  import {} from './modules/menu.js';
  import {} from './modules/modals.js';



/*---------------------------------------------------------------------------
  SLIDER                                                                                                                            
  --------------------------------------------------------------------------- */        

  import {heroslider} from './modules/keen-slider'
  heroslider()  

  import {thumbnailslider} from './modules/keen-slider'
  thumbnailslider() 

  import {productslider} from './modules/keen-slider'
  productslider() 



  // import {} from './modules/owl-carousel.js'



/*---------------------------------------------------------------------------
  ANIMATIONS                                                                                                                              
  --------------------------------------------------------------------------- */        

  import {} from './modules/animations'
 

/*---------------------------------------------------------------------------
  SOCIAL MEDIA                                                                                                                             
  --------------------------------------------------------------------------- */     

  import {} from './modules/shariff'


/*---------------------------------------------------------------------------
  DEV TOOLS                                                                                                                              
  --------------------------------------------------------------------------- */        

  // load RWD utilities 
  loadJS("./files/theme/dist/js/devtools.min.js");   



/*---------------------------------------------------------------------------
  COPYRIGHT PROTECTION                                                                                                             
  --------------------------------------------------------------------------- */

  let imagesCopyright = document.querySelectorAll('img')

  imagesCopyright.forEach(image => {

    image.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      alert('------------------------------------------------------\r\nBITTE RESPEKTIEREN SIE UNSER URHEBERRECHT!\r\n------------------------------------------------------\r\nKontaktieren Sie uns gerne unter info@dat-tripodi.de, \r\nfalls Sie die Bilder unserer Website verwenden wollen.');
    });

  })   



/*---------------------------------------------------------------------------
  CSS LOADING      
  https://github.com/filamentgroup/loadCSS   
  Inlined in HTML for faster asset loading                                                                                                                         
  --------------------------------------------------------------------------- */

  // !function(c){"use strict";function e(e,t,n,o){var r,i=c.document,d=i.createElement("link");if(t)r=t;else{var a=(i.body||i.getElementsByTagName("head")[0]).childNodes;r=a[a.length-1]}var f=i.styleSheets;if(o)for(var l in o)o.hasOwnProperty(l)&&d.setAttribute(l,o[l]);d.rel="stylesheet",d.href=e,d.media="only x",function e(t){if(i.body)return t();setTimeout(function(){e(t)})}(function(){r.parentNode.insertBefore(d,t?r:r.nextSibling)});var s=function(e){for(var t=d.href,n=f.length;n--;)if(f[n].href===t)return e();setTimeout(function(){s(e)})};function u(){d.addEventListener&&d.removeEventListener("load",u),d.media=n||"all"}return d.addEventListener&&d.addEventListener("load",u),(d.onloadcssdefined=s)(u),d}"undefined"!=typeof exports?exports.loadCSS=e:c.loadCSS=e}("undefined"!=typeof global?global:this);


/*---------------------------------------------------------------------------
  JS LOADING   
  https://github.com/filamentgroup/loadJS      
  Inlined in HTML for faster asset loading                                                                                                                         
  --------------------------------------------------------------------------- */
    
  // !function(c){function e(e,t,o){"use strict";var n,r=c.document.getElementsByTagName("script")[0],a=c.document.createElement("script");return"boolean"==typeof t&&(n=o,o=t,t=n),a.src=e,a.async=!o,r.parentNode.insertBefore(a,r),t&&"function"==typeof t&&(a.onload=t),a}"undefined"!=typeof module?module.exports=e:c.loadJS=e}("undefined"!=typeof global?global:this);


/*---------------------------------------------------------------------------
  RESPIMAGE
  https://github.com/aFarkas/respimage       
  Inlined in HTML for faster loading                                                                                                                         
  --------------------------------------------------------------------------- */

  // if(!window.HTMLPictureElement){
  //   document.createElement('picture');
  //   loadJS("files/theme/src/js/vendor/polyfills/picturefill.min.js");
  // }  


/*---------------------------------------------------------------------------
  VMINPOLY
  https://github.com/saabi/vminpoly      
  Inlined in HTML for faster loading/painting                                                                                                                        
  --------------------------------------------------------------------------- */

  // if (Modernizr.cssvw) {
  //   loadJS("files/theme/src/js/vendor/polyfills/vminpoly.js");
  // } 


/*---------------------------------------------------------------------------
  GLOBAL SMOOTH SCROLL                                                                                                                                 
  --------------------------------------------------------------------------- */

  // import smoothScroll from './modules/smooth-scroll.js'; 
  // smoothScroll();  


/*---------------------------------------------------------------------------
  DEBOUNCED RESIZE                                                                                                                       
  --------------------------------------------------------------------------- */

  // import {debouncedResize} from './modules/utilities.js'; 
  // debouncedResize(); 


/*---------------------------------------------------------------------------
  MOBILE BROWSER DETECTION                                                                                                                           
  --------------------------------------------------------------------------- */
   
  // import {mobileBrowserDetect} from './modules/utilities.js'; 
  // mobileBrowserDetect(); 


/*---------------------------------------------------------------------------
  DEVICE ORIENTATION CHECK                                                                                                                                  
  --------------------------------------------------------------------------- */

  // import {deviceOrientationCheck} from './modules/utilities.js'; 
  // deviceOrientationCheck(); 

  
/*---------------------------------------------------------------------------
  SCROLL DIRECTION CHECK                                                                                                                                  
  --------------------------------------------------------------------------- */

  // import {scrollDirectionCheck} from './modules/utilities.js'; 
  // scrollDirectionCheck();   