
/*---------------------------------------------------------------------------
  IMPORT 
  --------------------------------------------------------------------------- */

  import $ from 'jquery';


/*---------------------------------------------------------------------------
  CONTENT OVERLAYS                                                                                                                                     
  --------------------------------------------------------------------------- */

  $('.js-btn-modal').on('click', function(e){

    // prevent default action
    e.preventDefault();

    // fade out open modals
    $('.c-modal').fadeOut();

    //create semi-transparent content overlay
    manageOverlay('create');

    // fade in current modal
    $($(this).attr('href')).fadeIn();

  });



/*---------------------------------------------------------------------------
  OVERLAY
  used on sidebar, modals, etc.                                                                                                                                
  --------------------------------------------------------------------------- */
   
let docOverlay   = $('.c-page__overlay');

  docOverlay.hide();

  // create and destroy overlay
  function manageOverlay(action) {      
    if (action === 'create') {          
      docOverlay.css({'position':'fixed','pointer-events':'auto'}).fadeIn(300); 
    } else if (action === 'destroy') {             
      docOverlay.fadeOut(300, function(){
        docOverlay.css({'position':'relative','pointer-events':'none'});  
      });
    }      
  }

  // close overlay                   
  docOverlay.on('click', function(e) { 
    $('.c-modal').fadeOut();
    setTimeout(function() {              
      manageOverlay('destroy');              
    },150);              
  }); 
