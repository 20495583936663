
 /*---------------------------------------------------------------------------
  KEEN SLIDER
  https://keen-slider.io/                                                                                                                             
  --------------------------------------------------------------------------- */

  import KeenSlider from 'keen-slider';


/*---------------------------------------------------------------------------
  NAVIGATION
  --------------------------------------------------------------------------- */

    function navigation(slider) {
      let wrapper, dots, arrowLeft, arrowRight

      function markup(remove) {
        wrapperMarkup(remove)
        arrowMarkup(remove)
      }

      function createDiv(className) {
        var div = document.createElement("div")
        var classNames = className.split(" ")
        classNames.forEach((name) => div.classList.add(name))
        return div
      }

      function arrowMarkup(remove) {
        arrowLeft = createDiv("c-keenslider__arrow c-keenslider__arrow--left icon")
        arrowLeft.addEventListener("click", () => slider.prev())
        arrowRight = createDiv("c-keenslider__arrow c-keenslider__arrow--right icon")
        arrowRight.addEventListener("click", () => slider.next())

        wrapper.appendChild(arrowLeft)
        wrapper.appendChild(arrowRight)
      }

      function wrapperMarkup(remove) {
        wrapper = createDiv("c-keenslider__navwrapper")
        slider.container.parentNode.appendChild(wrapper)
        wrapper.classList.add("c-" + slider.container.getAttribute('id'))
        wrapper.appendChild(slider.container)
      }

      function updateClasses() {
        var slide = slider.track.details.rel
        slide === slider.track.details.slides.length - 1
          ? arrowRight.classList.add("arrow--disabled")
          : arrowRight.classList.remove("arrow--disabled")
      }

      slider.on("created", () => {
        markup()
        updateClasses()
        window.dispatchEvent(new Event('resize')) 
      })
      slider.on("slideChanged", () => {
        updateClasses()
      })
    }


/*---------------------------------------------------------------------------
  THUMBNAIL SLIDER
  --------------------------------------------------------------------------- */

  export const thumbnailslider = () => { 

    const thumbnailslider = document.getElementById('thumbnailslider');

    if(thumbnailslider) {

      let thumbslider = new KeenSlider("#thumbnailslider", {
        breakpoints: {
          "(min-width: 576px)": {
            slides: { perView: 2, spacing: 20 },
          },
          "(min-width: 768px)": {
            slides: { perView: 3, spacing: 20 },
          },
          "(min-width: 992px)": {
            slides: { perView: 4, spacing: 20 },
          } ,
          "(min-width: 1200px)": {
            slides: { perView: 5, spacing: 20 },
          },     
        },
        slides: { 
          perView: 2,
          spacing: 20 
        },
        loop: true,
      }, [navigation], [
        (thumbslider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              thumbslider.next()
            }, 2000)
          }
          thumbslider.on("created", () => {
            thumbslider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            thumbslider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          thumbslider.on("dragStarted", clearNextTimeout)
          thumbslider.on("animationEnded", nextTimeout) 
          thumbslider.on("updated", nextTimeout)
        },
      ] )
    }
  }



/*---------------------------------------------------------------------------
  HEROSLIDER
  https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/autoplay/autoswitch/javascript
  https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/media/fader/javascript
  https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/navigation-controls/arrows-and-dots/javascript
  --------------------------------------------------------------------------- */


  export const heroslider = () => { 

    let herosliders = document.querySelectorAll('.c-heroslider');

    if(herosliders) {

      herosliders.forEach((heroslider) => {

        var slider = new KeenSlider(heroslider, {
          slides: { 
            perView: 1, 
            spacing: 0 
          },
          loop: true,
          defaultAnimation: {
            duration: 1000,
            // https://easings.net/
            easing: (x) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x),
          },
        }, [navigation], [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ] )

      })
    }

  }


/*---------------------------------------------------------------------------
  PRODUCTSLIDER
  --------------------------------------------------------------------------- */


  export const productslider = () => { 

    let productsliders = document.querySelectorAll('.c-keenslider--product');

    if(productsliders) {

      productsliders.forEach((productsliders) => {

        var slider = new KeenSlider(productsliders, {
          slides: { 
            perView: 1, 
            spacing: 0 
          },
          loop: true,
          defaultAnimation: {
            duration: 1000,
            // https://easings.net/
            easing: (x) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x),
          },
        }, [navigation], [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ] )

      })
    }

  }


