/*---------------------------------------------------------------------------  
  _   _    ___     _____ ____    _  _____ ___ ___  _   _ 
 | \ | |  / \ \   / /_ _/ ___|  / \|_   _|_ _/ _ \| \ | |
 |  \| | / _ \ \ / / | | |  _  / _ \ | |  | | | | |  \| |
 | |\  |/ ___ \ V /  | | |_| |/ ___ \| |  | | |_| | |\  |
 |_| \_/_/   \_\_/  |___\____/_/   \_\_| |___\___/|_| \_|
 
  --------------------------------------------------------------------------- */                                                         


console.log('megamnue')


/*---------------------------------------------------------------------------
  NAVBAR                                                                                                                                     
  --------------------------------------------------------------------------- */

  // let navbarToggle = document.querySelector('.c-navbar__nav-toggle')
  // let navbarMenu = document.querySelector('.c-navbar')
  // let toggleCounter = 0

  // navbarToggle.addEventListener('click', event => {

  //   if(toggleCounter % 2 == 0) {
  //     navbarMenu.style.display = 'block' 
  //   } else {
  //     navbarMenu.style.display = 'none' 
  //   }

  //   toggleCounter++

  // })
