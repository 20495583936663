/*---------------------------------------------------------------------------
  SHARIFF
  https://github.com/heiseonline/shariff/                                                                                                                            
  --------------------------------------------------------------------------- */


  import {} from 'Shariff'


  let shariffDefault = document.querySelector('.js-shariff--default')

  // var shariffDefault = $('.js-shariff--default');

  new Shariff(shariffDefault, { 
    // backendUrl: '/files/scripts/social/shariff',
    orientation: 'vertical',
    theme: 'color', 
    orientation: 'horizontal', 
    buttonStyle: 'icon',
    lang: 'de',
    // mailUrl: 'mailto:info@dat-tripodi.de',
    mailSubject: 'DAT Handelsgesellschaft',
    mailBody: 'window.location.href',
    mediaUrl: '/files/layout/design/share.png',
    services: '[facebook,xing,linkedin,mail]'
  });


  /* news */

  
  let newsArticle = document.querySelector('.l-newslayout--full')

  if(newsArticle) { 

    console.log("news detail page");

    var newsH1 = document
      .getElementsByTagName('H1')[0]
      .getElementsByTagName('span')[0] 
      .innerHTML
    ;

    var newsImg = document
      .getElementsByClassName('l-newslayout--full')[0]
      .getElementsByTagName('figure')[0]
      .getElementsByTagName('img')[0]
      .getAttribute('src')
    ;
    
    var shariffNews = $('.js-shariff--news');
    new Shariff(shariffNews, {
      orientation: 'vertical',
      theme: 'color', 
      orientation: 'horizontal', 
      buttonStyle: 'icon',
      lang: 'de',
      // mailUrl: 'mailto:info@dat-tripodi.de',
      mailSubject: newsH1,
      mailBody: 'Aktueller Beitrag der DAT Handelsgesellschaft:\n' + window.location.href,  
      mediaUrl: newsImg, 
      services:'[facebook,xing,linkedin,mail]'
    });

  }

