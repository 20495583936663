/*---------------------------------------------------------------------------  
       _    ____ ____ ___  ____  ____ ___ ___  _   _ 
      / \  / ___/ ___/ _ \|  _ \|  _ \_ _/ _ \| \ | |
     / _ \| |  | |  | | | | |_) | | | | | | | |  \| |
    / ___ \ |__| |__| |_| |  _ <| |_| | | |_| | |\  |
   /_/   \_\____\____\___/|_| \_\____/___\___/|_| \_|
                                                     
  --------------------------------------------------------------------------- */                                                         

/*---------------------------------------------------------------------------
  CORE ACCORDION                                                                                                                                    
  --------------------------------------------------------------------------- */

// wenn n-accordion--nav dann bei win resize close

  console.log('accordions loaded')

  let accordions = Array.from(document.querySelectorAll('.n-accordion')).map(createAccordion);
  let scrollOffset = 90;

  function createAccordion(accordion) {
    
    console.log('accordion', accordion)

    let containers = Array.from(accordion.querySelectorAll(':scope > .n-accordion__container'));

    function getPanelHeight(panel) {
      panel.style.height = 'auto';
      let height = panel.getBoundingClientRect().height;
      panel.style.height = '';
      return height;
    }

    function open(container) {
      let panel = container.querySelector('.n-accordion__panel');
      let contentHeight = getPanelHeight(panel);
      container.dataset.open = 'true';
      // container.style.overflowY = 'auto';
      // container.style.height = '90vh';
      
      panel.style.height = '0px';
      
      requestAnimationFrame(() => {
        panel.style.height = `${contentHeight}px`;
      });

      panel.addEventListener('transitionend', function transitionEnd() {
        panel.removeEventListener('transitionend', transitionEnd);
        panel.style.height = 'auto';
      });

      const submenu = document.querySelector('.c-megamenu__content');


      // Check if the submenu exists
      if (submenu) {
        submenu.addEventListener('wheel', function (e) {
          // Prevent the scroll event from propagating to the body
          e.stopPropagation();
        });

        submenu.addEventListener('touch', function (e) {
          // Prevent the scroll event from propagating to the body
          e.stopPropagation();
        });
      } 


    }

    function close(container) {
      let panel = container.querySelector('.n-accordion__panel');
      let currentHeight = getPanelHeight(panel);
      
      panel.style.height = `${currentHeight}px`;
      panel.offsetHeight;
      
      panel.style.height = '0px';
      container.dataset.open = 'false';
    }


    containers.forEach(container => {
      let toggle = container.querySelector('.n-accordion__toggle');
      
      if (toggle) {

        toggle.addEventListener('click', (event) => {

          event.stopPropagation();

          if (event.currentTarget.parentNode === container) {

            let currentlyOpen = container.dataset.open === 'true';

            if (currentlyOpen) {

              close(container);

            } else {

              containers.forEach(c => close(c));
              open(container);

              // Add scroll behavior when accordion class includes 'n-accordion--scroll'
              if (accordion.classList.contains('n-accordion--scroll')) {
                setTimeout(() => {
                  let scrollPosition = toggle.getBoundingClientRect().top + window.pageYOffset - scrollOffset;
                  window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                }, 200);  // Adjust timeout as necessary
              }

            }
          }
        });
      }

      window.addEventListener('resize', () => {
        if (container.dataset.open === 'true') {
          let contentHeight = getPanelHeight(container.querySelector('.n-accordion__panel'));
          container.querySelector('.n-accordion__panel').style.height = `${contentHeight}px`;
        }
      });

    });

    return {
      containers,
      open,
      close
    };
  }


/*---------------------------------------------------------------------------
  EXTERNAL BUTTONS                                                                                                                                  
  --------------------------------------------------------------------------- */

  let buttons = document.querySelectorAll('.js-accordion__trigger[data-target]');
  buttons.forEach(openAccordionFromButton);

  function openAccordionFromButton(button) {
    button.addEventListener('click', (event) => {
      event.preventDefault();

      let targetContainerId = button.dataset.target;

      accordions.forEach(accordion => {
          accordion.containers.forEach(container => {
              if (container.id === targetContainerId) {
                  if (container.dataset.open === 'true') {
                      accordion.close(container);
                  } else {
                      // Close all other open containers before opening the target
                      accordion.containers.forEach(c => c.dataset.open === 'true' && accordion.close(c));
                      accordion.open(container);

                      // Add scroll behavior when the accordion's parent class includes 'n-accordion--scroll'
                      let accordionElement = container.closest('.n-accordion');
                      if (accordionElement && accordionElement.classList.contains('n-accordion--scroll')) {
                          setTimeout(() => {
                              let toggle = container.querySelector('.n-accordion__toggle');
                              let scrollPosition = toggle.getBoundingClientRect().top + window.pageYOffset - scrollOffset;
                              window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
                          }, 200);  // Adjust timeout as necessary
                      }
                  }
              }
          });
      });
    });
  }

 

// document.addEventListener('DOMContentLoaded', function () {
//   const submenu = document.querySelector('.c-megamenu__content');

//   // Check if the submenu exists
//   if (submenu) {
//     submenu.addEventListener('wheel', function (e) {
//       // Prevent the scroll event from propagating to the body
//       e.stopPropagation();
//     });
//   }
// });